import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import DataTable, { defaultThemes } from "react-data-table-component";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import moment from "moment";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiDelete,
} from "../../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";

export default function Notifaction() {
  const [notificationData, setNotificationData] = useState({});
  const [addBlog, setAddBlog] = useState(false);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState([]);
  const [astrologer, setAstrologer] = useState([]);
  const [selected, setSelected] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);

  //For close category model
  const addCategoryModelClose = (e) => {
    setAddBlog(false);
    setErrors(false);
    setNotificationData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setNotificationData({ ...notificationData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  //Form validation
  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (selected?.length == 0 || !selected) {
      formvalied = false;
      errors["userIds"] = "*Please select user";
    }
    if (!notificationData?.title?.trim() || notificationData?.title === "") {
      formvalied = false;
      errors["title"] = "*Please enter title.";
    }

    if (
      !notificationData?.message?.trim() ||
      notificationData?.message === ""
    ) {
      formvalied = false;
      errors["message"] = "*Please enter message.";
    }
    setErrors(errors);
    return formvalied;
  };
  useEffect(() => {
    getUserData();
    getAstrologerData();
  }, []);

  const getUserData = async () => {
    await ApiGet(`user/get-user?user=true`)
      .then((res) => {
        console.log("?????", res?.data?.payload?.data);
        setUserData(res?.data?.payload?.data);
      })
      .catch((err) => { });
  };

  const getFunctions = () => {
    let data = selected;
    let func = [];
    for (let i = 0; i < data?.length; i++) {
      let funcValue = data[i];
      func.push(funcValue?.value);
    }
    return func;
  };

  const getAstrologerData = async () => {
    // await ApiGet(`user/admin/get-astrologer?&page=${page}&limit=${countPerPage}`)
    await ApiGet(`user/admin/get-astrologer?limit=5000000&isAll=true`)
      .then((res) => {
        console.log("astrologerees", res);
        setAstrologer(res?.data?.payload?.data);
      })
      .catch((err) => { });
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setNotificationData({ ...notificationData, [name]: e.target.files[0] });
    // setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };
  const addBlogData = async (e) => {
    const selectedAstrologer = getFunctions()

    // if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      let fileArr = Array.from(selectedAstrologer);


      var isSelectedAll = false;
      if (astrologer?.length == selectedAstrologer.length || userData?.length == selectedAstrologer.length) {
        isSelectedAll = true;
      } else {
        isSelectedAll = false
      }

      if (isSelectedAll) {
        formData.append("userIds", "67b236029764aedaf0e7f3f1");
      } else {
        fileArr.forEach((selectedAstrologer) => {
          formData.append("userIds", selectedAstrologer);
        });
      }

      if (astrologer?.length == selectedAstrologer.length) {
        formData.append("userType", "allAstrologer");
      } else if (userData?.length == selectedAstrologer.length) {
        formData.append("userType", "allUser");
      } else {
        formData.append("userType", notificationData?.role == "astrologer" ? "astrologer" : "user");
      }

      formData.append("title", notificationData?.title);
      formData.append("scheduledAt", notificationData?.scheduledAt);
      formData.append("message", notificationData?.message);
      formData.append("file", notificationData?.file);
     
      
      await ApiPost(`scheduler/schedule-notification`, formData)
        .then((res) => {
          toast.success("Notification scheduled successfully");
          setAddBlog(false);
          setSelected([]);
          setNotificationData({});
          setLoader(false);
          getScheduleNotificationData();
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
          setLoader(false);
        });
    // }
  };



   const getScheduleNotificationData = async () => {
      setLoadingData(true);
      const url = 'scheduler/get-schedule-notification';
      try {
        const res = await ApiGet(url);
        // console.log('res?.data',res?.data);
        

        setNotificationList(res?.data?.payload || []);
        setCount(res?.data?.payload?.count || 0);
      } catch (err) {
        toast.error("Failed to fetch data");
      } finally {
        setLoadingData(false);
      }
    };
  
    useEffect(() => {
      getScheduleNotificationData();
    }, []);
  
  
     const deleteScheduleNotification = async (id) => {
        await ApiDelete(`scheduler/delete-schedule-notification?id=${id}`)
          .then((res) => {
            if (res?.status === 200) {
              toast.success("Delete Schedule Notification successfully");
              getScheduleNotificationData();
            } else {
              toast.error("something went wrong please try again");
            }
          })
          .catch((err) => {
            toast.error("something went wrong please try again");
          });
      };

    
    const customStyles = {
      header: {
        style: { minHeight: "56px" },
      },
      cells: {
        style: {
          "&:not(:last-of-type)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
          },
        },
      },
    };


 const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    { name: "Title", selector: (row) => row?.title, sortable: true },
    { name: "Message", selector: (row) => row?.message, sortable: true },
    { name: "Scheduled For", selector: (row) => row?.userType, sortable: true },
    {
      name: "Schedule At",
     selector: (row) => moment.utc(row?.scheduledAt).format("Do MMM YYYY h:mm A"),
      width: "18%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
        className="btn btn-danger btn-sm"
        onClick={() =>  deleteScheduleNotification(row?._id)}
      >
        Delete
      </button>
      ),
    },
  ];



  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Schedule Notifaction</h2>
            </div>

            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-color mr-2"
                onClick={() => {
                  setAddBlog(true);
                }}
              >
                Send Notification
              </button>
            </div>
          </div>
        </div>
      </div>



      <div className="card p-1">
        <div className="p-2 mb-2">
          {/* <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Schedule Notifaction</h2>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleSearch(e)}
                value={search}
                placeholder="Search"
              />
            </div>
          </div> */}
          {loadingData ? (
            <div className="d-flex justify-content-center mt-4">
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={notificationList}
              customStyles={customStyles}
              highlightOnHover
              // pagination
              // paginationServer
              // paginationTotalRows={count}
              // paginationPerPage={countPerPage}
              // paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              // paginationDefaultPage={page}
              // onChangePage={(page) => setPage(page)}
              // onChangeRowsPerPage={(rowPerPage) => setCountPerPage(rowPerPage)}
            />
          )}
        </div>
      </div>



      {/* Open add photographer model */}
      {addBlog === true ? (
        <Dialog fullScreen open={addBlog}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addCategoryModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="ml-40">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  select role
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className="form-control rounded-lg custom-style"
                    aria-label="Default select example"
                    name="role"
                    onChange={(e) => onhandleChange(e)}
                  >
                    <option value="user">User</option>
                    <option value="astrologer">Astrologer</option>
                  </select>
                </div>
              </div>
              {notificationData?.role === "astrologer" ? (
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Astrologer
                  </label>
                  <div className="col-lg-9 col-xl-6">

                    <div className="col-lg-9 col-xl-6">
                      {astrologer?.length > 0 && (
                        <>

                          <MultiSelect
                            options={astrologer?.map((e, key) => {
                              return {
                                label: e?.userName,
                                value: e?._id,
                              };
                            })}
                            value={selected}
                            name="userIds"
                            onChange={(e) => setSelected(e)}
                          />
                        </>
                      )}

                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["userIds"]}
                      </span>

                    </div>
                  </div>
                </div>
              ) : (
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    User
                  </label>
                  <div className="col-lg-9 col-xl-6 px-0">
                    <div className="col-lg-9 col-xl-6">
                      {userData?.length > 0 && (
                        <>

                          <MultiSelect
                            options={userData?.map((e, key) => {
                              return {
                                label: e?.userName,
                                value: e?._id,
                              };
                            })}
                            value={selected}
                            name="userIds"
                            onChange={(e) => setSelected(e)}
                          />
                        </>
                      )}

                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["userIds"]}
                      </span>

                    </div>
                  </div>
                </div>
              )}


              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Schedule Date
                </label>
                <div className="col-lg-6 col-xl-3">
                  <div>
                    <input
                      type="datetime-local"
                      className={`form-control form-control-lg form-control-solid `}
                      id="scheduledAt"
                      name="scheduledAt"
                      min={new Date().toISOString().slice(0, 16)}
                      max={new Date(new Date().setDate(new Date().getDate() + 1))
                        .toISOString()
                        .slice(0, 10) + "T23:59"}
                      value={notificationData?.scheduledAt}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["title"]}
                  </span>
                </div>
              </div>


              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Title
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="title"
                      name="title"
                      value={notificationData?.title}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["title"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Message
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <textarea
                      // type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="message"
                      name="message"
                      value={notificationData?.message}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["message"]}
                  </span>
                </div>
              </div>


              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  file
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <div className="labelMainDiv">
                      <label htmlFor="imageUpload" className="lableForImage">
                        select file
                      </label>
                      {notificationData?.companyLogo ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid `}
                      name="file"
                      id="imageUpload"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>
                  <div>
                    {notificationData?.file && (
                      <img
                        style={{
                          height: "150px",
                          width: "270px",
                          margin: "15px 0",
                        }}
                        src={
                          notificationData?.file
                            ?.toString()
                            ?.includes(".com") === true
                            ? notificationData?.file
                            : URL.createObjectURL(notificationData?.file)
                        }
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["file"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center">
                    <button className="btn btn-color" type="button">
                      Send Notification
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="btn btn-color center"
                  onClick={(e) => {
                    addBlogData(e);
                  }}
                >
                  Send Notifaction
                </button>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
