import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import Userlist from "../_metronic/components/Userlist";
import Coupen from "../_metronic/components/Coupen/Coupen";
import Banner from "../_metronic/components/Banner/Banner";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout"; 
import Client from "../_metronic/layout/components/Client/Client";
import DashboardPage from "./pages/DashboardPage"; 
import Astrologer from "../_metronic/components/Astrologer";
import Blog from "../_metronic/components/Car/Blog/Blog";
import ContactUs from "../_metronic/components/Contact-US/ContctUs";
import Feedback from "../_metronic/components/Feedback/Feedback";
import Horoscope  from "../_metronic/components/Horoscope/Horoscope";
import Searchkundli  from "../_metronic/components/Searchkundli/Searchkundli";

import SupportUser from "../_metronic/components/Support-User/SupportUser";
import RaiseTicket from "../_metronic/components/Raise-ticket/RaiseTicket";
import Chat from "../_metronic/components/Chat/Chat";
import TotalUser from "../_metronic/components/TotalUser/TotalUser";
import Astrologertransaction from "../_metronic/components/Astrologer transaction/Astrologertransaction";
import AstrologertransactionLogs from "../_metronic/components/Astrologer transaction/AstrologertransactionLogs";
import Usertransaction from "../_metronic/components/User transaction/Usertransaction";
import Userpaymentlogs from "../_metronic/components/Userpaymentlogs/Userpaymentlogs";

import Notifaction from "../_metronic/layout/components/Notification/Notifaction";
import ScheduleNotifaction from "../_metronic/layout/components/ScheduleNotifaction/ScheduleNotifaction";

export default function BasePage() { 

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <ContentRoute exact path="/dashboard" component={DashboardPage} />
          <ContentRoute exact path="/total-userlist" component={TotalUser} />
          <ContentRoute exact path="/blog" component={Blog} />
          <ContentRoute exact path="/Coupen" component={Coupen} />
          <ContentRoute exact path="/Banner" component={Banner} />
          <ContentRoute exact path="/client" component={Client} />
          <ContentRoute exact path="/astrologer" component={Astrologer} /> 
          <ContentRoute exact path="/contact-us" component={ContactUs} /> 
          <ContentRoute exact path="/feedback" component={Feedback} /> 
          <ContentRoute exact path="/horoscope" component={Horoscope} /> 
          <ContentRoute exact path="/search-kundli" component={Searchkundli} /> 
          
          <ContentRoute exact path="/support-user" component={SupportUser} /> 
          <ContentRoute exact path="/raise-ticket" component={RaiseTicket} /> 
          <ContentRoute exact path="/user-chat" component={Chat} /> 
          <ContentRoute exact path="/astrologer-transaction/:astroId" component={Astrologertransaction}/>
          <ContentRoute exact path="/astro-transaction-detail" component={AstrologertransactionLogs}/>
          <ContentRoute exact path="/user-transaction" component={Usertransaction}/>
          <ContentRoute exact path="/user-paymentlogs" component={Userpaymentlogs}/>
          
          <ContentRoute exact path="/notification" component={Notifaction}/>
          <ContentRoute exact path="/schedule-notification" component={ScheduleNotifaction}/>
          


          <Redirect to="error/error-v6" />
        </Switch>
      </Suspense>
    </>
  );
}